<template>
    <popup-shell @close="$emit('close')">
        <div class="contents">
            <language-btns v-model="selectedLang"></language-btns>
            <div class="info">
                <div class="inputs">
                    <div class="input-line">
                        <span>Kort navn</span>
                        <input type="text" v-model="shortName" />
                    </div>
                    <div class="input-line">
                        <span>Fullt navn</span>
                        <input type="text" v-model="fullName" />
                    </div>
                </div>
                <div class="inputs">
                    <div class="input-line">
                        <span>Maks antall skatter</span>
                        <integer-input v-model="maxTreasures" />
                    </div>
                    <div class="input-line">
                        <span>Maks antall vandringer</span>
                        <integer-input v-model="maxAdventures" />
                    </div>
                </div>
            </div>
            <div class="inputs">
                <div class="input-line">
                    <span>Tilknyttet kommune</span>
                    <dropdown :values="municipalities" v-model="municipality" placeholder="Velg kommune..." :searchFilter="(val, query) => val.name.toLowerCase().indexOf(query.toLowerCase()) != -1">
                        <template #default="{ value }">{{ value.name }}</template>
                    </dropdown>
                </div>
                <div class="input-line">
                    <span>Registreringsinfo</span>
                    <textarea v-model="registrationInformation[selectedLang?.key ?? defaultLang]" />
                </div>
                <div class="input-line">
                    <span>Midtpunkt</span>
                    <google-map v-if="location" v-model="location" />
                </div>
            </div>
            <div class="buttons">
                <button @click="save">Lagre</button>
                <button @click="$emit('close')">Avbryt</button>
            </div>
        </div>
    </popup-shell>
</template>

<script>
import { GeoPoint } from 'firebase/firestore'
import { defineComponent } from 'vue'
import Municipality from '@/types/Municipality'
import GoogleMap from '@/components/GoogleMap.vue'
import IntegerInput from '@/components/IntegerInput.vue'
import Dropdown from '@/components/Dropdown.vue'

export default defineComponent({
    name: 'EditArea',
    components: {
        GoogleMap,
        IntegerInput,
        Dropdown,
    },
    props: {
        area: {
            type: Object,
            required: false,
        },
    },
    data: () => ({
        shortName: '',
        fullName: '',
        location: null,
        defaultLang: 'no',
        registrationInformation: {},
        baseCoinChance: 0,
        coinChanceDecay: 0,
        maxDailyCoins: 0,
        maxTreasures: 0,
        maxAdventures: 0,
        municipality: null,

        selectedLang: null,
    }),
    computed: {
        municipalities() {
            return this.$store.state.Areas.municipalities;
        },
    },
    methods: {
        async save() {
            const data = {
                shortName: this.shortName,
                fullName: this.fullName,
                location: new GeoPoint(this.location.lat, this.location.lng),
                defaultLang: this.defaultLang,
                maxTreasures: this.maxTreasures,
                maxAdventures: this.maxAdventures,
                registrationInformation: this.registrationInformation[this.defaultLang],
                municipality: this.municipality?.id,
                tr: { registrationInformation: this.registrationInformation },
            }
            delete data.tr[this.defaultLang];
            if (this.area) {
                data.id = this.area.id;
                await this.$store.dispatch('Areas/updateArea', data);
            } else {
                await this.$store.dispatch('Areas/createArea', data);
            }
            this.$emit('close');
        },
    },
    mounted() {
        if (this.area) {
            this.shortName = this.area.shortName;
            this.fullName = this.area.fullName;
            this.location = { lat: this.area.location.latitude, lng: this.area.location.longitude };
            this.defaultLang = this.area.defaultLang;
            this.maxTreasures = this.area.maxTreasures;
            this.maxAdventures = this.area.maxAdventures;
            if (this.area.municipality) this.municipality = this.municipalities.filter(municipality => municipality.id == this.area.municipality)[0];

            this.baseCoinChance = this.area.baseCoinChance;
            this.coinChanceDecay = this.area.coinChanceDecay;
            this.maxDailyCoins = this.area.maxDailyCoins;

            this.registrationInformation[this.defaultLang] = this.area.registrationInformation;
            if (this.area.tr && this.area.tr.registrationInformation) {
                for (let lang in this.area.tr.registrationInformation) {
                    this.registrationInformation[lang] = this.area.tr.registrationInformation[lang];
                }
            }
        } else {
            this.location = { lat: 0, lng: 0 };
        }
        this.selectedLang = this.$store.state.Languages.languages.filter(lang => lang.key == this.defaultLang)[0];
    },
});
</script>

<style lang="scss" scoped>
.contents {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .info {
        display: flex;
        gap: 20px;
    }

    .inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .input-line {
            display: flex;
            flex-direction: column;

            span {
                text-align: left;
            }
        }
    }
}
</style>